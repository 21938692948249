.navbar {
    position: fixed;
    background-color: black;
    transition: width 0.7s ease;
    overflow: hidden;
    box-sizing: border-box;
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    transition: 0.7s;
}

.nav-item {
    width: 100%;
}

.nav-item:last-child {
    margin-top: auto;
}

.nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    text-decoration: none;
    /*filter: grayscale(100%) opacity(0.7);*/
    transition: 0.7s;
}


.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    background: rgba(0, 0, 0, 0.72);
    color: #FFD744;
}


.link-text {
    display: none;
    margin-left: 1rem;
    color: white;
    opacity: 0;
    font-family: Lato, sans-serif;
}

.link-icon{
    color: white;
    font-family: Lato, sans-serif;
    padding: 25px;
    font-size: 40px;
}

.nav-link:hover .link-icon{
    color: #FFD744;
    transition: 0.7s;
}

.nav-link svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 1.5rem;
}

.fa-primary {
    color: #ffffff;
}

.fa-secondary {
    color: #FFD744
}

.fa-primary,
.fa-secondary {
    transition: 0.7s;
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

.logo-text
{
    display: inline;
    position: absolute;
    left: -950px;
    transition: 0.5s;
    padding: 20px;
}

.contact-img {
    width: 30px;
    height: 30px;
    color: white;
    padding: 20px;
}


/* Small screens */
@media only screen and (max-width: 600px) {
    .link-icon{
        color: white;
        font-family: Lato, sans-serif;
        padding: 5px 5px;
        font-size: 36px;
    }

    .navbar {
        bottom: 0;
        width: 100%;
        height: 5rem;
        padding: 10px;
    }

    .logo {
        margin-top: 15px;
        margin-right: 0;
        pointer-events: none;
        display: none;
    }

    .navbar-nav {
        flex-direction: row;

    }

    .nav-link {
        justify-content: center;
        height: 40px;
        width: 97%;
    }

    main {
        margin: 0;
    }
}

/* Large screens */
@media only screen and (min-width: 600px) {
    .navbar {
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .navbar:hover {
        width: 16rem;
    }

    .navbar:hover .link-text{
        display: inline;
        animation: ease 0.3s normal textShow forwards;
    }

    @keyframes textShow{
        0%{opacity:0}
        80%{opacity:0}
        100%{opacity:1}
    }

    .navbar:hover .logo svg
    {
        margin-left: 11rem;
    }

    .navbar:hover .logo-text
    {
        left: 0px;
    }

    .navbar:hover .logo svg {
        transform: rotate(-180deg);
    }
}

.theme-icon {
    display: none;
}

