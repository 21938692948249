/* contact.css */

/* Styles for the form container */
.send-to-insta {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin-top: 80px;
}

/* Styles for each field in the form */
.contact-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-family: BlackOpsOne;
    text-align: center;
}

/* Styles for labels */
.contact-field label {
    margin-bottom: 5px;
}

/* Styles for text inputs and textarea */
.contact-text {
    /* Add any specific styling you need for these elements */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style for the submit button */
.send-btn {
    margin-top: 10px;
    padding: 5px;
    width: 200px;
    font-family: BlackOpsOne;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;

}
