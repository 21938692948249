.App {
  text-align: center;
  height: 100vh;
  background-color: black;
}

.navbar:hover .App{
  filter: grayscale(100%) opacity(0.7);
}

.progress{
  font-family: Lato, sans-serif;
  padding-top: 20px;
  font-size: 24px;
  width: 450px;
}

.percentage-progress{
  color: white;
}

.percentage-flex{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.h1-main{
  color: white;
  font-family: Lato, sans-serif;
  font-size: 80px;
  padding-top: 50px;
}

.h1-title {
  font-family: Lato, sans-serif;
  font-size: 28px;
}


.App-header {
  background-color: #000000;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 0;
}



@media only screen and (max-width: 600px) {

  .progress{
    width: 250px;
  }

  .h1-main{
    font-size: 40px;
  }

  .App-header {
    justify-content: center;
    padding-bottom: 50px;
  }

}



@media only screen and (min-width: 600px) {

 .h1-main{
   margin: 0;
   font-size: 40px;
 }




}





