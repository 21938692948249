body {
  margin: 0;
}


@font-face {
  font-family: "BlackOpsOne";
  src: local("BlackOpsOne"),
       url("./fonts/BlackOpsOne-Regular.ttf") format("truetype");
}
